<script setup lang="ts">
import { HorizontalProps } from "./Horizontal.props"
import { sm, md, lg } from "~/utils/breakpoints"

const props = withDefaults(defineProps<HorizontalProps>(), {
  promoCode: "",
  text: "",
  cta: undefined
})

const isCardTypeFullWidth = ref(props.cardType === "fullWidth")
const isCardTypeWithPromoCode = ref(props.cardType === "withPromoCode")

const bgImage = computed(() => {
  if (!!props.bgImageSmall && sm.value) return props.bgImageSmall
  else if (!!props.bgImageMedium && md.value && !lg.value)
    return props.bgImageMedium

  return props.bgImageLarge
})

const emit = defineEmits<{ (e: "onCopy", value: string): void }>()

const isPromoCodeCopied = ref(false)

const handleCopy = () => {
  navigator.clipboard.writeText(props.promoCode)
  emit("onCopy", props.promoCode)
  isPromoCodeCopied.value = true
}
</script>

<template>
  <div
    class="horizontal-banner-wrapper relative w-full"
    :class="[
      isTextWhite ? 'text-white' : 'text-black-main',
      isCardTypeFullWidth
        ? 'rounded-none px-6 py-3 md:rounded-lg md:px-12 md:py-6 '
        : 'flex w-full items-center rounded-lg p-4',
      !isCardTypeFullWidth && (fullHeight ? 'h-full' : 'py-6 md:h-full')
    ]"
  >
    <UtilsWrapperImage
      v-if="bgImage"
      v-bind="bgImage"
      :key="bgImage.image.url"
      class="
        wrapper-image-full-container-centered
        absolute
        inset-0
        overflow-hidden
      "
      :class="isCardTypeFullWidth ? '-z-10 md:rounded-lg' : '-z-0 rounded-lg'"
    />
    <div
      class="flex w-full flex-col justify-between gap-2 md:gap-4"
      :class="{
        'z-10 ': !isCardTypeFullWidth
      }"
    >
      <p
        v-if="title"
        :class="
          isCardTypeFullWidth
            ? 'elephant-bold md:whale-bold'
            : 'hyppo-bold text-center'
        "
      >
        {{ title }}
      </p>
      <UtilsMarkdown
        v-if="text"
        :class="
          isCardTypeFullWidth ? 'pig-bold md:elephant-bold' : 'pig text-center'
        "
        :content="text"
      />
      <!-- Promo code -->
      <div class="flex w-full flex-col items-center">
        <UtilsMarkdown
          v-if="
            promoCodeInfoText && isCardTypeWithPromoCode && !isPromoCodeCopied
          "
          class="promo-code__info-text"
          :content="promoCodeInfoText"
        />
        <UtilsMarkdown
          v-else-if="
            promoCodeInfoTextCopied &&
            isCardTypeWithPromoCode &&
            isPromoCodeCopied
          "
          class="promo-code__info-text"
          :content="promoCodeInfoTextCopied"
        />
        <div
          v-if="isCardTypeWithPromoCode && promoCode && !isPromoCodeCopied"
          class="promo-code__button cursor-pointer"
          @click="handleCopy"
        >
          <UtilsIcon name="Copy.svg" class="h-6 w-6" /> {{ promoCode }}
        </div>
        <div
          v-else-if="
            isCardTypeWithPromoCode && promoTextCopied && isPromoCodeCopied
          "
          class="promo-code__button"
        >
          <UtilsIcon name="CheckmarkCircle2.svg" class="h-6 w-6" />
          {{ promoTextCopied }}
        </div>
      </div>
      <!-- Link button -->
      <UtilsButton
        v-if="cta && !isCardTypeWithPromoCode"
        :class="{
          'custom-btn-no-padding pig self-start  text-white hover:underline':
            isCardTypeFullWidth,
          'w-fit self-center': !isCardTypeFullWidth
        }"
        v-bind="cta"
        :theme="
          isCardTypeFullWidth
            ? isTextWhite
              ? 'link-white'
              : 'link-black'
            : 'contained-white'
        "
        order="last"
      >
        <template #icon
          ><UtilsIcon name="DiagonalArrowRightUp.svg" class="h-5 w-5"
        /></template>
      </UtilsButton>
    </div>
  </div>
</template>

<style scoped lang="scss">

.promo-code__info-text {
  @apply snail-bold md:mouse-bold mb-2 flex flex-col text-center uppercase;
}

.promo-code__button {
  @apply beaver-bold
    flex
    w-full
    items-center
    justify-center
    gap-1
    bg-white
    py-2
    text-black-main;
}

</style>
